import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class ErpFeaturesitem extends Component {
    render(){
        let {rowClass, roundClass, image, title, text, buttonText, url} = this.props;
        return(
            <div className={`row erp_item_features ${rowClass}`}>
                <div className="col-lg-6">
                    <div className="erp_features_img_two">
                        <div className={`img_icon ${roundClass}`}><span className="pluse_1"></span><span className="pluse_2"></span><i className="icon_lightbulb_alt"></i></div>
                        <img src={require ("../../img/erp-home/" + image)} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="erp_content_two">
                        <div className="hosting_title erp_title pb-0">
                            <h2>{ title }</h2>
                            <p>{ text }</p>
                        <Link to={url} className="erp_btn_learn">{ buttonText }<i className="arrow_right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ErpFeaturesitem;