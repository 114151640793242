import React, { Component } from "react";
import { postSuscribeForm } from "../services/services";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "1px solid #ccc",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    transition: "all 0.5s ease",
  },
};

Modal.setAppElement("#root");

class ServiceSubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      modalIsOpen: false,
      modalMessage: "",
      success: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleInputChange = (e) => {
    this.setState({ email: e.target.value });
  };

  openModal(message) {
    this.setState({ modalIsOpen: true, modalMessage: message });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, modalMessage: "" });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = { email: this.state.email };
    const response = await postSuscribeForm({ data });

    if (response) {
      if (response.status === 200) {
        this.openModal("¡Gracias por tu interés! Nos pondremos en contacto contigo.");
      } else {
        this.openModal("¡Ups! Algo salió mal. Por favor, inténtalo de nuevo.");
      }
    }
  };

  render() {
    let { sClass } = this.props;
    const { modalIsOpen, modalMessage } = this.state;
    return (
      <div className="container">
        <div className={`subscribe_form_info text-center ${sClass}`}>
          <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">
            Suscríbete y recibe increíbles ofertas
          </h2>
          <form onSubmit={this.handleSubmit} className="subscribe-form">
            <input
              type="email"
              className="form-control"
              placeholder="Ingresa tu email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
            <button type="submit" className="btn_hover btn_four mt_40">
              Subscribe
            </button>
          </form>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Mensaje del formulario"
        >
          <h3>Gracias por contactarnos!</h3>
          <div className="py-2">{modalMessage}</div>
          <button
            className="btn btn-pill btn-success"
            onClick={this.closeModal}
          >
            Cerrar
          </button>
        </Modal>
      </div>
    );
  }
}

export default ServiceSubscribe;
