import React from 'react';

const TrackingPrice =()=>{
    return(
        <section className="tracking_price_area sec_pad">
            <div className="container">
                <div className="tracking_price_inner wow fadeInUp">
                    <h2 className="text-left">Desde</h2>
                    <div className="d-flex track_body">
                        <div className="track_price">
                            <h3>$149.990</h3>
                            <h6>+ IVA</h6>
                        </div>
                    </div>
                    <div className="text-center">
                        <a href="/#" className="software_banner_btn btn_submit f_500">Contratar</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TrackingPrice;