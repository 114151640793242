import React from 'react';
import TrackingLogo from '../TrackingLogo';

const TrackingBanner =()=>{
    return(
        <section className="tracking_banner_area text-center">
            <div className="container">
                <div className="tracking_banner_content">
                    <h3>Desarrollo Web con<strong> Wordpress</strong> </h3>
                    <p>Aumenta tu exposición en internet</p>
                    <img width="50%" src={require ("../../img/home4/banner_img.png")} alt=""/>
                </div>
                <TrackingLogo/>
            </div>
        </section>
    )
}

export default TrackingBanner;