import React from 'react';
import Reveal from 'react-reveal';
import {Link} from 'react-router-dom';

const AppGetstarted =()=>{
    return(
        <section className="get_started_area">
            <div className="shap one"></div>
            <div className="shap two"></div>
            <div className="shap one three"></div>
            <div className="shap two four"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                                <h3 className="f_400 f_p wow fadeInLeft" data-wow-delay="0.2s">Contáctanos</h3>
                                <h2 className="f_700 f_p f_size_40 l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Cotiza tu App hoy</h2>
                                <p className="f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">No esperes más para empezar a materializar tus ideas en una aplicación, con gusto responderemos tus consultas e inquietudes</p>
                                <Link to="/Process" className="app_btn app_btn_one wow fadeInLeft" data-wow-delay="0.5s">Conoce el proceso</Link>
                                <Link to="/Contact" className="app_btn app_btn_two wow fadeInLeft" data-wow-delay="0.6s">Contacto</Link>
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-lg-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home7/iPhoneX2.png')} alt=""/></Reveal>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AppGetstarted;