import React from 'react';
import Sectitle from '../Title/Sectitle';
import ErpServiceitem from './ErpServiceitem';

const ErpService =()=> {
    return(
        <section className="erp_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title erp_title text-center" Title=' Eficiente, Accesible y Conveniente ' TitleP='Conoce los beneficios de desarrollar una aplicación web para administrar tus activos.'/>
                <div className="row">
                    <ErpServiceitem itemClass="pr_70" sTitle="Seguridad" pText="Protege tu información con seguridad avanzada y confiable." icon="erp_icon1.png"/>
                    <ErpServiceitem itemClass="pr_70" sTitle="Sé exclusivo" pText="Diferénciate con soluciones personalizadas y únicas." icon="erp_icon2.png"/>
                    <ErpServiceitem itemClass="pl_70" sTitle="Te damos soporte" pText="Nuestro equipo está aquí para ayudarte en todo momento." icon="erp_icon3.png"/>
                    <ErpServiceitem itemClass="pr_70" sTitle="Genera tus documentos" pText="Crea y gestiona documentos de forma rápida y sencilla." icon="erp_icon4.png"/>
                    <ErpServiceitem itemClass="pr_70" sTitle="Ahorra tiempo" pText="Automatiza procesos y optimiza tu productividad." icon="erp_icon5.png"/>
                    <ErpServiceitem itemClass="pl_70" sTitle="Trabaja online" pText="Accede y gestiona tu negocio desde cualquier lugar." icon="erp_icon6.png"/>
                </div>
            </div>
        </section>
    )
}

export default ErpService;