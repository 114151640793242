import axios from "axios";

export function getURL() {
  return "https://api.diggitaly.cl";
}


function validarFormulario(campos) {
  for (const campo in campos) {
    if (verifySqlInjection(campos[campo])) {
      return false;
    }
  }

  for (const campo in campos) {
    if (verifyXSS(campos[campo])) {
      return false;
    }
  }
  
  return true;
}

async function obtenerIpYReportar(input) {
  try {
    const validar = validarFormulario(input);
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    const ip = data.ip;

    if (!validar) {
      await saveSuspiciousIP(ip);
      return { isSuspicious: true, isBanned: false };
    }

    const response$ = await fetch(getURL() + "/suspicousIP/" + ip)
    const data$ = await response$.json();
    if (data$.is_suspicious) {
      return { isSuspicious: true, isBanned: true };
    }

    return { isSuspicious: false , isBanned: false};
  } catch (error) {
    console.error("Error al obtener la IP:", error);
    return { isSuspicious: false, isBanned: false };
  }
}

export const postContactForm = async (data) => {
  try {
    const { isSuspicious, isBanned } = await obtenerIpYReportar(data);

    if (isSuspicious && !isBanned) {
      alert("¡Cuidado! Hemos detectado un intento de intrusión. Tu IP ha sido reportada.");
      return;
    }

    if (isBanned && isSuspicious) {
      alert("¡Cuidado! Tu IP ha sido reportada por comportamiento sospechoso.");
      return;
    }
    
      const token = await getToken();
      const response$ = await fetch(getURL() + "/contact/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": token,
        },
         body: JSON.stringify(data),
        });
  
        return response$;
  } catch (error) {
    console.error("Error al enviar la solicitud POST:", error);
  }
}

export const postSuscribeForm = async (data) => {
  try {
    const { isSuspicious, isBanned } = await obtenerIpYReportar(data);

    if (isSuspicious && !isBanned) {
      alert("¡Cuidado! Hemos detectado un intento de intrusión. Tu IP ha sido reportada.");
      return;
    }

    if (isBanned && isSuspicious) {
      alert("¡Cuidado! Tu IP ha sido reportada por comportamiento sospechoso.");
      return;
    }

      const token = await getToken();
      const response = await fetch(getURL() + "/suscribe/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": token,
        },
        body: JSON.stringify(data),
      });

      return response;
  } catch (error) {
    console.error("Error al enviar la solicitud POST:", error);
  }
}

export const postWebRevisionForm = async (data) => {
  try {
    const { isSuspicious, isBanned } = await obtenerIpYReportar(data);

    if (isSuspicious && !isBanned) {
      alert("¡Cuidado! Hemos detectado un intento de intrusión. Tu IP ha sido reportada.");
      return;
    }

    if (isBanned && isSuspicious) {
      alert("¡Cuidado! Tu IP ha sido reportada por comportamiento sospechoso.");
      return;
    }
      const token = await getToken();

      const response = await fetch(getURL() + "/webRevision/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": token,
        },
        body: JSON.stringify(data),
      });
      return response;
  }
  catch (error) {
    console.error("Error al enviar la solicitud POST:", error);
  }
}
    
export const getToken = async () => {
  const response = await axios.get(getURL() + "/token/");
  if (response) {
    return response.data.token;
  } else {
    console.error("Error al obtener el token CSRF");
  }
};

export const getServicesList = async () => {
  try {
    const response = await axios.get(getURL() + "/services/");
    return response.data;
  } catch (error) {
    console.error("Error al obtener la lista de servicios:", error);
  }
};

const saveSuspiciousIP = async (ip) => {
  await axios.post(getURL() + "/suspicousIP/", {
    ip: ip,
  });
}

function verifySqlInjection(input) {
  const patronesSQL = [
    /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|UNION|ALTER|CREATE|TRUNCATE|EXEC)\b)/i,
    /(\b(OR|AND)\b\s*\d*\s*=\s*\d*)/i,
    /(;|--)/,
    /(['"]\s*(OR|AND|=|LIKE|DROP|UNION|SELECT)\s*['"])/i,
  ];

  return patronesSQL.some((patron) => patron.test(input));
}

function verifyXSS(input) {
  const patronesXSS = [
    /(<\s*script\b[^>]*>.*?<\s*\/\s*script\s*>)/i,  // Detecta etiquetas de script completas
    /(javascript:)/i,                                // Detecta URL con "javascript:" 
    /(<\s*iframe\b[^>]*>.*?<\s*\/\s*iframe\s*>)/i,   // Detecta etiquetas iframe
    /(<\s*img\b[^>]*on\w+\s*=\s*['"][^'"]*['"])/i,   // Detecta atributos onerror, onclick, etc., en etiquetas img
    /(on\w+\s*=\s*['"][^'"]*['"])/i,                 // Detecta eventos como onclick, onerror, etc., en cualquier atributo
    /(<\s*\/?\s*(iframe|img|div|object|embed|form|link|style|svg|meta|base|applet)\b[^>]*>)/i,  // Detecta etiquetas HTML comunes en XSS
    /(\b(alert|prompt|confirm|eval|console|debugger)\s*\()/i  // Detecta funciones de JavaScript usadas en ataques
  ];

  return patronesXSS.some((patron) => patron.test(input));
}

