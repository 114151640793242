import React, {Component} from 'react';
import TrackingProductItem from './TrackingProductItem';


class TrackingProduct extends Component{
    render(){
        return(
            <section className="tracking_product_area sec_pad">
                <div className="container">
                    <TrackingProductItem image="watch_1.png" TrackingTitle="Rápida implementación"  Trackingd="Creamos páginas web funcionales y atractivas de forma rápida y eficiente. En poco tiempo, podrás tener tu sitio optimizado para recibir visitantes, sin sacrificar calidad ni personalización." Trackingbtn="Sobre Wordpress"/>
                    <TrackingProductItem itemClass="tracking_pr_two" rowclass="flex-row-reverse" image="crismas.png" TrackingTitle="Variedad de plantillas"  Trackingd="Selecciona la plantilla que más se adecúe a tus necesidades, tenemos cientos de plantillas disponibles para tí." Trackingbtn="Consultar plantillas"/>
                    <TrackingProductItem itemClass="tracking_pr_three" image="month.png" TrackingTitle="Consultar Pasarelas"  Trackingd="Administra tus pagos de forma fácil y segura con nuestras integraciones de pasarelas de pago. Acepta tarjetas de crédito, débito y métodos de pago digitales, optimizando tu proceso de cobro y mejorando la experiencia del cliente." Trackingbtn="Consultar por pasarelas"/>
                </div>
            </section>
        )
    }
}

export default TrackingProduct;