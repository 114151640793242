import React from 'react';
import Reveal from 'react-reveal';
import {Link} from 'react-router-dom';

const AppFeatures = () => {
    return(
        <section className="app_featured_area_two">
            <div className="triangle_shape"></div>
            <div className="container">
                <div className="row app_feature_info">
                    <div className="col-lg-5">
                        <div className="app_img">
                            <div className="dot dot_one wow fadeIn" data-wow-delay="0.5s"><span className="dot1"></span><span className="dot2"></span></div>
                            <div className="dot dot_two wow fadeIn" data-wow-delay="0.8s"><span className="dot1"></span><span className="dot2"></span></div>
                            <div className="dot dot_three wow fadeIn" data-wow-delay="0.3s"><span className="dot1"></span><span className="dot2"></span></div>
                            <Reveal effect="fadeInLeft" duration={2500}><img className="text_bg one" src={require('../../img/home7/text_one.png')} alt=""/></Reveal>
                            <Reveal effect="fadeInDown" duration={2000}><img className="text_bg two" src={require('../../img/home7/text_three.png')} alt=""/></Reveal>
                            <Reveal effect="fadeInRight" duration={1500}><img className="text_bg three" src={require('../../img/home7/text_two.png')} alt=""/></Reveal>
                            <img className="wow fadeIn phone_img" src={require('../../img/home7/iPhonex.png')} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="app_featured_content">
                                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">Nos adaptamos a todos los<br/> dispositivos</h2>
                                <p className="f_400">Creamos aplicaciones nativas y de alta calidad tanto para Apple como para Android de escritorio garantizando un rendimiento impecable en ambas plataformas. Ya sea iOS o Android, te ofrecemos soluciones que maximizan la experiencia del usuario y aprovechan al máximo las características de cada sistema operativo.</p>
                                <Link to="./Contact" className="app_btn btn_hover mt_40">Contactar con Ventas</Link>
                            </div>
                        </Reveal>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AppFeatures;
