import React from 'react';
import ErpFeaturesitem from './ErpFeaturesitem';

const ErpFeatures =()=>{
    return(
        <section className="erp_features_area_two sec_pad">
            <div className="container">
                <ErpFeaturesitem 
                    title="Beneficios de Desarrollar Software Propio para Gestión de Activos"
                    text="Desarrollar un software a medida para gestionar los activos de tu empresa te ofrece flexibilidad, optimización de procesos, y soluciones adaptadas a tus necesidades específicas. Además, permite una fácil escalabilidad y un mejor control sobre tus operaciones."
                    rowClass="align-items-center flex-row-reverse" 
                    buttonText="Contactar Ventas"
                    image="crm_img1.jpg"
                    url="/Contact"/>
                <ErpFeaturesitem
                    title="Las cuentas claras"
                    text="Un software personalizado te permite trazar y gestionar datos de manera precisa, ofreciendo control total sobre transacciones y activos. Con información clara y actualizada, optimizas la eficiencia y minimizas errores en la gestión diaria."
                    rowClass="align-items-center" 
                    image="crm_img2.jpg"
                    buttonText="Solicitar Demo" 
                    url="/Contact"
                    roundClass="red"/>
                <ErpFeaturesitem
                    title="Funcionalidad y Diseño en Perfecta Armonía"
                    text="Un software eficiente no tiene por qué sacrificar el buen diseño. Con una solución personalizada, tu empresa puede disfrutar de herramientas funcionales y visualmente atractivas, asegurando una experiencia de usuario intuitiva sin comprometer el rendimiento ni la estética."
                    rowClass="align-items-center flex-row-reverse" 
                    image="crm_img3.jpg" 
                    buttonText="Proceso de Desarrollo"
                    roundClass="green"
                    url="/Process"/>
            </div>
        </section>
    )
}
export default ErpFeatures;