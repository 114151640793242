import React, {Component} from 'react';

class TrackingLogo extends Component {
    constructor(){
        super();
        this.state = {
            clientsLogo: [
                {
                    id: 1,
                    url: '#',
                    col: "col",
                    image: 'logo-elementor.png',
                },
                {
                    id: 2,
                    url: '#',
                    col: "col",
                    image: 'logo-gris-circular.png',
                },
                {
                    id: 3,
                    url: '#',
                    col: "col",
                    image: 'woo-logo.png',
                },
            ]
        }
    }
    render(){
        return(
            <div className="row tracking_software_logo">
                {
                    this.state.clientsLogo.map(item=>(
                        <div className={item.col} key={item.id}>
                            <a href={item.url}><img src={require ("../img/home-tracking/" + item.image)}alt=""/></a>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default TrackingLogo;