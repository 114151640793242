import React, { Component } from 'react';
import { postContactForm, getServicesList } from '../services/services';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: '1px solid #ccc',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.5s ease',
  },
};

Modal.setAppElement('#root');

class Contacts extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      website: '',
      service: 0,
      message: '',
      servicesList: [],
      modalIsOpen: false,
      modalMessage: '',
      isSubmitting: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(message) {
    this.setState({ modalIsOpen: true, modalMessage: message });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, modalMessage: '' });
    window.location.reload();
  }

  async componentDidMount() {
    try {
      const services = await getServicesList();
      this.setState({ servicesList: services.services });
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { name, email, website, service, message } = this.state;

    const formData = {
      name,
      email,
      website,
      service,
      message,
    };

    try {
      const response = await postContactForm(formData);
      if (response && response.status === 200) {
        this.openModal("Tu mensaje ha sido enviado con éxito!");
      } else {
        this.openModal("Hubo un error al enviar tu mensaje. Por favor intenta de nuevo.");
      }
    } catch (error) {
      this.openModal("Hubo un error al enviar tu mensaje. Por favor intenta de nuevo.");
    }
  };

  render() {
    const { servicesList, modalIsOpen, modalMessage, isSubmitting } = this.state;
    return (
      <section className="contact_info_area sec_pad bg_color">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contacto</h6>
                <span className="f_400 t_color3">Ventas:</span>
                <p className="f_400 f_size_15"><a href="tel:3024437488">(+096) 302 443 7488</a></p>
                <p className="f_400 f_size_15"><a href="tel:3024437488">(+096) 204 353 6684</a></p>
                <span className="f_400 t_color3">Email:</span>
                <p className="f_400 f_size_15"><a href="mailto:crea@diggitaly.cl">crea@diggitaly.cl</a></p>
              </div>
            </div>
            <div className="contact_form col-lg-9">
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Déjanos un mensaje</h2>
              <form onSubmit={this.submitForm} method="post" id="contactForm">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="name">Nombre</label>
                      <input className="form-control" type="text" id="name" name="name" onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input className="form-control" type="text" name="email" id="email" onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="website">Página Web</label>
                      <input className="form-control" type="text" name="website" id="website" onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="service">Servicio</label>
                      <select className="form-control" name="service" id="service" onChange={this.handleChange} value={this.state.service}>
                        <option value="0">Selecciona un servicio</option>
                        {servicesList.map((service) => (
                          <option key={service.id} value={service.id}>
                            {service.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="message">Mensaje</label>
                      <textarea className="form-control" onChange={this.handleChange} name="message" id="message" cols="30" rows="10"></textarea>
                    </div>
                  </div>
                </div>
                <button
              id="submit"
              type="submit"
              className="btn_three"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Enviando...' : 'Enviar Mensaje'}
            </button>
              </form>
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Mensaje del formulario"
        >
          <h3>Gracias por contactarnos!</h3>
          <div className='py-2'>{modalMessage}</div>
          <button className='btn btn-pill btn-success' onClick={this.closeModal}>Cerrar</button>
        </Modal>
      </section>
    );
  }
}

export default Contacts;
