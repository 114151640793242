import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterSecurity from '../components/Footer/FooterSecurity';
import EventDetails from '../components/Features/EventDetails';
import FooterData from '../components/Footer/FooterData';

const About = () => {
    return(
        <div className="body_wrapper">
        <CustomNavbar cClass="custom_container" hbtnClass="new_btn"/>
        <div className="m-5 p-5">
            <EventDetails />
            </div>
            <FooterSecurity FooterData={FooterData}/>
        </div>
    )
}
export default About;