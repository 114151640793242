import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Featuresitems from '../components/Features/Featuresitems';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
const Process = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Asesoría Emprendedores" Pdescription="Te ayudamos a entender el potencial de tu negocio, analizamos las oportunidades y te proponemos un curso de acción"/>
            <section className="process_area bg_color sec_pad">
                <div className="container">
                    <div className="features_info">
                        <img className="dot_img" src={require ('../img/home4/divider.png')} alt=""/>
                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="process_1.png" iImg="icon01.png" ftitle="Analizamos tu idea" descriptions="En este primer paso, nos sumergimos en la esencia de tu concepto empresarial. A través de un análisis detallado, exploramos cada aspecto de tu idea para desarrollar estrategias sólidas que impulsen su viabilidad y éxito. Desde los cimientos de tu visión, trabajamos para convertirla en un plan estratégico sólido y factible"/>
                        <Featuresitems rowClass="row" aClass="pl_100" fimage="process_2.png" iImg="icon02.png" ftitle="Conversamos contigo sobre análisis clave" descriptions="Llevamos a cabo una investigación exhaustiva y focalizada en tu negocio. Esta fase se centra en recopilar información clave que potencie tu proyecto, identificando tendencias del mercado, necesidades del público objetivo y oportunidades de crecimiento. Esta información se convierte en el pilar fundamental para el diseño de estrategias efectivas." />
                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="process_3.png" iImg="icon3.png" ftitle="Conversamos contigo sobre análisis clave" 
                        descriptions="En esta etapa, establecemos un diálogo continuo contigo para compartir los hallazgos y análisis clave. Combinamos tu visión como emprendedor con nuestra experiencia y conocimientos. Esta interacción permite fusionar diferentes perspectivas para definir estrategias más sólidas y adaptadas a tus metas empresariales."/>
                        <Featuresitems rowClass="row" aClass="pl_100" fimage="process_4.png" iImg="icon_04.png" ftitle="Generamos una propuesta en base al análisis y la retroalimentación contigo." 
                        descriptions="Basados en el análisis y en las conversaciones previas, creamos una propuesta personalizada. Transformamos los datos analíticos en soluciones tangibles y concretas que se alinean directamente con tus necesidades y objetivos empresariales. Esta propuesta detallada es el mapa que guiará el desarrollo de tu proyecto."/>
                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="process_5.png" iImg="icon_05.png" ftitle="Propuesta personalizada" 
                        descriptions="Finalmente, una vez que aceptas la propuesta, nos sumergimos en el desarrollo y lanzamiento de tu proyecto digital. Este proceso se lleva a cabo paso a paso, asegurando que cada aspecto se ajuste a tus requerimientos específicos. Desde la creación de servicios hasta el diseño y lanzamiento de tu página web, trabajamos para hacer realidad tu visión empresarial de manera meticulosa y personalizada."/>
                        <div className="dot middle_dot"><span className="dot1"></span><span className="dot2"></span></div>
                    </div>
                </div>
            </section>
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default Process;