import React from 'react';
import Reveal from 'react-reveal';
const MarketingGetstarted = () => {
    return(
        <React.Fragment>
            <section className="get_started_three sec_pad">
                <div className="container">
                    <div className="sec_title text-center mb_70">
                        <h2 className="f_p f_size_30 l_height45 f_600 t_color3">Administra tus activos <br/>con tu propio software</h2>
                    </div>
                    <div className="startup_tab_img">
                        <Reveal effect="fadeInLeft">
                            <div className="web_img">
                                <img src={require('../img/new/web_image.jpg')} alt=""/>
                            </div>
                        </Reveal>
                        <Reveal effect="fadeInRight"><div className="phone_img"><img src={require('../img/new/iPhoneX.png')} alt=""/></div></Reveal>
                    </div>
                    <div className="row get_btn_info">
                        <div className="col-md-6">
                            <div className="get_btn_item">
                                <div className="get_icon">
                                    <i className="ti-time"></i>
                                </div>
                                <h3>Ahorra tiempo en tus procesos</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="get_btn_item">
                                <div className="get_icon icon_two">
                                    <i className="icon_cloud-upload_alt"></i>
                                </div>
                                <h3>Accede desde cualquier parte del mundo</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="intregration_area bg_color sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="row intregration_logo">
                                <div className="col-6 intregration_item">
                                    <a href="https://www.webpay.cl/" className="intregration_icon d-flex justify-content-center"><img src={require('../img/new/webpay-logo.png')} target="_blank" rel="noopener noreferrer" alt=""/></a>
                                </div>
                                <div className="col-6 intregration_item">
                                    <a href="https://stripe.com/es" className="intregration_icon d-flex justify-content-center"><img src={require('../img/new/stripe-logo.png')} target="_blank" rel="noopener noreferrer" alt=""/></a>
                                </div>
                                <div className="col-6 intregration_item">
                                    <a href="https://www.flow.cl/" className="intregration_icon d-flex justify-content-center"><img src={require('../img/new/logo-flow.jpg')} target="_blank" rel="noopener noreferrer" alt=""/></a>
                                </div>
                                <div className="col-6 intregration_item">
                                    <a href="https://www.mercadopago.cl/" className="intregration_icon d-flex justify-content-center"><img src={require('../img/new/mercado-pago-logo.png')} target="_blank" rel="noopener noreferrer" alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 d-flex align-items-center">
                            <div className="intregration_content">
                                <h2>Integraciones de pasarelas de pago</h2>
                                <p>Administra tus pagos de forma fácil y segura con nuestras integraciones de pasarelas de pago. Acepta tarjetas de crédito, débito y métodos de pago digitales, optimizando tu proceso de cobro y mejorando la experiencia del cliente.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default MarketingGetstarted;