import React, { Component } from "react";
import SeoTitle from "../components/Title/SeoTitle";
import MarketingGetstarted from "../components/MarketingGetstarted";
import { postWebRevisionForm } from "../services/services";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    zIndex: 9999998,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    transition: "all 0.5s ease",
    zIndex: 9999999,
    position: 'absolute',
  },
};

Modal.setAppElement("#root");

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      website: "",
      email: "",
      modalIsOpen: false,
      modalMessage: "",
      isSubmitting: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  openModal(message) {
    this.setState({ modalIsOpen: true, modalMessage: message });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, modalMessage: "" });
    window.location.reload();
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { website, email } = this.state;
    const response = await postWebRevisionForm({ website, email });

    if (response) {
      if (response.status === 200) {
        this.openModal("¡Gracias por tu interés! Nos pondremos en contacto contigo para realizar la revisión de tu web.");
      } else {
        this.openModal("¡Ups! Algo salió mal. Por favor, inténtalo de nuevo.");
      }
    }
  };
  render() {
    const { modalIsOpen, modalMessage, isSubmitting } = this.state;
    return (
      <>
      <React.Fragment>
        <section className="seo_subscribe_area">
          <div className="overlay_img"></div>
          <div className="cloud_img">
            <img src={require("../img/seo/cloud.png")} alt="" />
          </div>
          <div className="container">
            <SeoTitle
              Title="¡Quiero mejorar mi web!"
              TitleP="Envíanos tu correo y tu web, te contactaremos para saber más detalles de tí y tu emprendimiento!"
            />
            <form
              onSubmit={this.handleSubmit}
              className="row seo_subscribe_form"
            >
              <div className="input-group col-lg-5 col-md-5 col-sm-6">
                <input
                  type="url"
                  name="website"
                  id="website"
                  placeholder="Web URL"
                  className="form-control"
                  value={this.state.website}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="input-group col-lg-5 col-md-4 col-sm-6">
                <input
                  type="email"
                  name="email"
                  id="emails"
                  placeholder="Email"
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="input-group col-lg-2 col-md-3 col-sm-12">
                <button
                  type="submit"
                  name="submit"
                  className="check-btn"
                  disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Enviando...' : 'Check'}
                  </button>
              </div>
            </form>
          </div>
        </section>
        <MarketingGetstarted />
        <section className="seo_call_to_action_area sec_pad">
          <div className="container">
            <div className="seo_call_action_text text-center">
              <h2>
                ¿Quieres empezar?
                <br /> Comunícate con ventas!
              </h2>
              <a
                href="/Contact"
                className="btn btn-light p-4 d-flex align-items-center justify-content-center"
              >
                <h4 className="mb-0 text-color-suscribe">HABLAR CON VENTAS</h4>
              </a>
            </div>
          </div>

        </section>
      </React.Fragment>
                <Modal
                isOpen={modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Mensaje del formulario"
              >
                <h3>Gracias por contactarnos!</h3>
                <div className="py-2">{modalMessage}</div>
                <button
                  className="btn btn-pill btn-success"
                  onClick={this.closeModal}
                >
                  Cerrar
                </button>
              </Modal>
              </>
    );
  }
}

export default Subscribe;
