import React from 'react';

const TrackingActivity =()=>{
    return(
        <section className="tracking_activity_area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 my-2">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/activity_icon.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">Rápida implementación</h3>
                                <p>Desplegamos tu página web en poco tiempo, sin perder calidad ni funcionalidad</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 my-2">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/time_icon.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">Controla tus métricas</h3>
                                <p>Te entregamos herramientas para que analices las métricas de tu página web</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 my-2">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/analyze_icon.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">Brinda confianza</h3>
                                <p>Tus clientes tendrán mayor seguridad en comprar tus productos o servicios </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TrackingActivity;