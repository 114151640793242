import React, { Component } from "react";

class EventSponserLogo extends Component {
  render() {
    let { image, url, title } = this.props;
    return (
      <div className="col-lg-3 col-sm-4 col-6">
          <button type="button"  data-toggle="tooltip" data-placement="top" title={title} className="btn btn-simple">
        <a href={url} className="analytices_logo wow fadeInUp">
            <img src={require("../img/home-software/" + image)} alt="" />
        </a>
          </button>
      </div>
    );
  }
}
export default EventSponserLogo;
