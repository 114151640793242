import React, { Component } from "react";
import { Link } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import { postSuscribeForm } from "../../services/services";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "1px solid #ccc",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    transition: "all 0.5s ease",
  },
};

Modal.setAppElement("#root");

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      modalIsOpen: false,
      modalMessage: "",
      success: false,
      isSubmitting: false,
    };
  }

  openModal(message) {
    this.setState({ modalIsOpen: true, modalMessage: message });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, modalMessage: "" });
    window.location.reload();
  };

  handleInputChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const response = await postSuscribeForm({ email: this.state.email });
    if (response) {
      if (response.status === 200) {
        this.openModal("¡Gracias por tu interés! Nos pondremos en contacto contigo.");
      } else {
        this.openModal("¡Ups! Algo salió mal. Por favor, inténtalo de nuevo.");
      }
    }
  };

  render() {
    let FooterData = this.props.FooterData;
    const { modalIsOpen, modalMessage, isSubmitting } = this.state;
    return (
      <footer className="new_footer_area bg_color">
        <div className="new_footer_top">
          <div className="container">
            <div className="row">
              {FooterData.CompanyWidget.map((widget) => {
                return (
                  <Reveal effect="fadeInLeft" duration={500} key={1}>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="f_widget company_widget wow fadeInLeft"
                        data-wow-delay="0.2s"
                      >
                        <Link to="/" className="f-logo ">
                          <img
                            src={require("../../img/logo-dark.png")}
                            width={"100%"}
                            alt=""
                          />
                        </Link>
                        <p className="pt-3">{widget.description}</p>
                        <form
                          onSubmit={this.handleSubmit}
                          className="f_subscribe_two mailchimp"
                        >
                          <input
                            className="form-control memail"
                            type="email"
                            placeholder="Ingresa tu email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                          />
                          <button
                            className="btn btn_get btn_get_two"
                            type="submit"
                            disabled={isSubmitting}
                            >
                              {isSubmitting ? 'Enviando...' : 'Enviar Mensaje'}
                          </button>
                          <p
                            className="mchimp-errmessage"
                            style={{ display: "none" }}
                          ></p>
                          <p
                            className="mchimp-sucmessage"
                            style={{ display: "none" }}
                          ></p>
                        </form>
                      </div>
                    </div>
                  </Reveal>
                );
              })}
              {FooterData.AboutWidget.map((widget) => {
                return (
                  <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="f_widget about-widget pl_70 wow fadeInLeft"
                        data-wow-delay="0.4s"
                      >
                        <h3 className="f-title f_600 t_color f_size_18">
                          {widget.title}
                        </h3>
                        <ul className="list-unstyled f_list">
                          {widget.menuItems.map((item) => {
                            return (
                              <li key={item.id}>
                                <Link to={item.url}>{item.text}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Reveal>
                );
              })}
              {FooterData.SocialLinks.map((widget) => {
                return (
                  <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                    <div className="col-lg-3 col-md-6">
                      <div className="f_widget about-widget pl_70 wow fadeInLeft">
                        <h3 className="f-title f_500 t_color f_size_18 mb_40">
                          {widget.title}
                        </h3>
                        <ul className="list-unstyled f_list">
                          {widget.menuItems.map((item) => {
                            return (
                              <li key={item.id}>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.url}
                                >
                                  {item.text}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Reveal>
                );
              })}
            </div>
          </div>
          <div className="footer_bg">
            <div className="footer_bg_one"></div>
            <div className="footer_bg_two"></div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-7">
                <p className="mb-0 f_400">{FooterData.copywrite}</p>
              </div>
              <div className="col-lg-6 col-sm-5 text-right">
                <p>
                  Hecha con <i className="icon_heart"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Mensaje del formulario"
        >
          <h3>Gracias por contactarnos!</h3>
          <div className="py-2">{modalMessage}</div>
          <button
            className="btn btn-pill btn-success"
            onClick={this.closeModal}
          >
            Cerrar
          </button>
        </Modal>
      </footer>
    );
  }
}
export default Footer;
