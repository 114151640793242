import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const Service = () => {
    return(
        <React.Fragment>
            <section className="seo_service_area sec_pad">
                <div className="container">
                    <SeoTitle Title='Que ofrecemos?' TitleP='Una amplia gama de servicios de desarrollo para que materialices tus ideas de la manera más eficiente posible y a un costo accesible'/>
                    <div className="row seo_service_info">
                        <Fade bottom duration={500}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon7.png')} alt=""/>
                                    <Link to="/desarrollo-web">
                                        <h4>Desarrollo Web con Wordpress</h4>
                                    </Link>
                                    <p>Ofrecemos soluciones de Desarrollo Web con Wordpress, creando sitios dinámicos y autoadministrables que garantizan una experiencia de usuario fluida y adaptada a tus necesidades.</p>
                                    <Link to="/desarrollo-web"><i className="arrow_right"></i></Link>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={700} >
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/logo8.png')} alt=""/>
                                    <Link to="/desarrollo-software">
                                        <h4>Desarrollo de Aplicaciones Web</h4>
                                    </Link>
                                    <p>Desarrollamos aplicaciones web multiplataforma personalizadas, centradas en ofrecer soluciones eficientes, seguras, escalable y adaptadas a los objetivos específicos de tu negocio.</p>
                                    <Link to="/desarrollo-software"><i className="arrow_right"></i></Link>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon6.png')} alt=""/>
                                    <Link to="/desarrollo-app">
                                        <h4>Desarrollo de Aplicaciones Móviles</h4>
                                    </Link>
                                    <p>Desarrollamos aplicaciones móviles personalizadas y optimizadas para ofrecer una experiencia de usuario intuitiva, adaptadas a tus necesidades y enfocadas en un rendimiento eficiente.</p>
                                    <Link to="/desarrollo-app"><i className="arrow_right"></i></Link>
                                </div>
                            </div>
                        </Fade>
                        <div className="col-lg-12 text-center mt_40">
                            <Link to="/Process" className="seo_btn seo_btn_one btn_hover">Conoce el proceso</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                <h2 className="wow fadeInUp">Date a conocer</h2>
                                <h6 className="wow fadeInUp">Aumenta tu exposición con una página web Wordpress autoadministrable.</h6>
                                    <p>Al trabajar con nosotros en el desarrollo de tu sitio web, recibirás herramientas intuitivas para gestionar el rendimiento y optimizar el posicionamiento de tu página. Podrás ajustar fácilmente el contenido y la estructura según las necesidades de tu negocio, asegurando un crecimiento sostenido y mejor visibilidad en línea.</p>
                                    <Link to="/desarrollo-web" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Saber más</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img_two.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    
                                    <h2 className="wow fadeInUp">Contruye una aplicación web a tu medida</h2>
                                    <h6 className="wow fadeInUp">Organiza la información de tu empresa de una manera ordenada desarrollando una aplicación web de navegador a la medida.</h6>
                                    <p className="wow fadeInUp">Desarrollar tu propia aplicación web te ofrece una solución personalizada que optimiza los procesos específicos de tu empresa. Esto te brinda mayor control sobre las operaciones, mejora la eficiencia y reduce costos a largo plazo, facilitando la toma de decisiones con acceso a información en tiempo real desde cualquier lugar.</p>
                                    <Link to="/desarrollo-software" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Saber más</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/home4/work2.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp">Construye la aplicación que siempre soñaste</h2>
                                    <h6 className="wow fadeInUp">Desde la idea hasta la palma de tu mano.</h6>
                                    <p className="wow fadeInUp">Convertimos tus ideas en realidad con nuestro servicio de Desarrollo de Aplicaciones Web móviles. Si siempre has soñado con crear una app que refleje tus ideas y deseos, estamos aquí para ayudarte. Colaboramos contigo para entender tu visión y transformarla en una solución intuitiva y funcional, diseñada para atraer y satisfacer a tus usuarios. Juntos, haremos que tu concepto cobre vida y destaque en el mercado.</p>
                                    <Link to="/desarrollo-app" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Saber más</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Service;
