import React,{Component} from 'react';
import Sectitle from '../Title/Sectitle'
import { Link } from 'react-router-dom';

class AppWork extends Component{
    render(){
        let ServiceData = this.props.ServiceData;
        return(
            <section className="app_service_area" id="work">
                <div className="container">
                    <Sectitle sClass="sec_title text-center mb_70" Title="Como Trabajamos" tClass="t_color3" TitleP="Conoce el proceso de desarrollo para tu aplicación móvil."/>
                    <div className="row app_service_info">
                        {
                            ServiceData.work.map(items => {
                                return(
                                    <div className="col-lg-4"key={items.id}>
                                        <div className="app_service_item wow fadeInUp" data-wow-delay="0.2s">
                                            <i className={`app_icon ${items.icon}`}></i>
                                            <h5 className="f_p f_size_18 f_600 t_color3 mt_40 mb-30">{items.title}</h5>
                                            <p className="f_400 f_size_15 mb-30">{items.p}</p>
                                        </div>
                                    </div> 
                                )
                            })
                        }
                    </div>
                    <div className="text-center mt_70">
                        <Link to="/Process" className="app_btn btn_hover mt_40 my-4">Conoce el proceso</Link>
                        </div>
                </div>
            </section>
        )
    }
}
export default AppWork;