import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {

    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;

        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass} container`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo-light.png")} width="27%" alt=""/>
                            <img src={require("../img/logo-dark.png")} width="27%" alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                {/* <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                                    <Link to="./" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Servicios
                                    </Link>
                                    <div className="mega_menu_inner">
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <ul className="dropdown-menu scroll">
                                                <li className="nav-item">
                                                    <NavLink to="/Home-chat" exact className="item">
                                                        <span className="img">
                                                            <span className="rebon_tap">New</span>
                                                        </span>
                                                        <span className="text">
                                                            Hoeme Chat
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/desarrollo-web" exact className="item">
                                                        <span className="img">
                                                            <span className="rebon_tap">New</span>
                                                        </span>
                                                        <span className="text">
                                                            Desarrollo Web con Wordpress
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/Home-event" exact className="item">
                                                        <span className="img">
                                                            <span className="rebon_tap">New</span>
                                                        </span>
                                                        <span className="text">   
                                                            Home Event
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/Home-cloud" exact className="item">
                                                        <span className="img">
                                                            <span className="rebon_tap">New</span>
                                                        </span>
                                                        <span className="text">   
                                                            Cloud Based Saas
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/desarrollo-software" exact className="item">
                                                        <span className="img">
                                                        </span>
                                                        <span className="text">
                                                            Desarrollo de Aplicación Web
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/HomeHosting" exact className="item">
                                                        <span className="img">
                                                        </span>
                                                        <span className="text">
                                                            Hoeme Hosting
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/HomeSecurity" exact className="item">
                                                        <span className="img">
                                                        </span>
                                                        <span className="text">
                                                            Hoeme Security
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/home-support" exact className="item">
                                                        <span className="img">
                                                        </span>
                                                        <span className="text">
                                                            Hoeme Support
                                                        </span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                        <NavLink to="/desarrollo-app" exact className="item">
                                                            <span className="text">
                                                                Desarrollo de Aplicaciones Moviles
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="/" exact className="item">
                                                            <span className="img">
                                                            </span>
                                                            <span className="text">
                                                                Prototyping Tool
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="/Payment-processing" exact className="item">
                                                            <span className="img">
                                                            </span>
                                                            <span className="text">
                                                                Payment Processing
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="/Startup" exact className="item">
                                                            <span className="img">
                                                            </span>
                                                            <span className="text">
                                                                Startup
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="/Digital-marketing" className="item">
                                                            <span className="img">
                                                            </span>
                                                            <span className="text">
                                                                Digital Marketing
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="/HR-Management" className="item">
                                                            <span className="img">
                                                            </span>
                                                            <span className="text">
                                                                HR Management
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="/Home-CRM" exact className="item">
                                                            <span className="img">
                                                            </span>
                                                            <span className="text">
                                                                CRM Software
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                 */}
                                {/* <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Dominios</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Service" className="nav-link" to='/Service'>Service</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/ServiceDetails'>Dominios</NavLink></li>
                                    </ul>
                                </li> */}
                                    <li className="nav-item"><NavLink title="Inicio" className="nav-link border-bottom" to="../../">Inicio</NavLink></li>
                                    <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Diggitaly</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Process" className="nav-link" to='/Process'>Proceso</NavLink></li>

                                        {/*
                                        <li className="nav-item"><NavLink exact title="Faq" className="nav-link" to='/Faq'>Preguntas Frecuentes</NavLink></li>
                                        */}

                                        <li className="nav-item"><NavLink exact title="About" className="nav-link" to='/About'>Nosotros</NavLink></li>
                                        {/* <li className="nav-item"><NavLink exact title="Team" className="nav-link" to='/Team'>Team</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Price" className="nav-link" to='/Price'>Price</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="SignIn" className="nav-link" to='/SignIn'>Sign In</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="SignUp" className="nav-link" to='/SignUp'>Sign Up</NavLink></li> */}
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <Link to="./Services" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Servicios</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        {/* <li className="nav-item"><NavLink exact title="Process" className="nav-link" to="/Services">Todos nuetros servicios</NavLink></li> */}
                                        <li className="nav-item"><NavLink exact title="Process" className="nav-link" to="/desarrollo-app"> Desarrollo de Aplicaciones Móviles</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Faq" className="nav-link" to="/desarrollo-software">Desarrollo de Aplicación Web</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="About" className="nav-link" to="/desarrollo-web">Desarrollo Web con Wordpress</NavLink></li>
                                        {/* <li className="nav-item"><NavLink exact title="Team" className="nav-link" to='/Team'>Team</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Price" className="nav-link" to='/Price'>Price</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="SignIn" className="nav-link" to='/SignIn'>Sign In</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="SignUp" className="nav-link" to='/SignUp'>Sign Up</NavLink></li> */}
                                    </ul>
                                </li>

                                <li className="nav-item"><NavLink title="contact" className="nav-link" to="/Contact">Contacto</NavLink></li>

                            </ul>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;