import React from "react";
import EventSponserLogo from "./EventSponserLogo";

const EventSponser = () => {
  return (
    <section className="event_sponser_area sec_pad">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2>
            <span>Tecnologías</span>
          </h2>
        </div>
        <div className="event_sponser_inner">
          <div className="event_sponser_item">
            <div className="row">
              <div className="col-lg-3 col-md-4  d-flex align-items-center">
                <h4 className="h_head sponser_title p-0">Lenguajes</h4>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="row d-flex align-content-center">
                  <EventSponserLogo title="Python" url="https://www.python.org/" image="python-logo.png" />
                  <EventSponserLogo title="Java" url="https://www.java.com/" image="java-logo.png" />
                  <EventSponserLogo title="Javascript" url="https://developer.mozilla.org/en-US/docs/Web/JavaScript" image="javascript-logo.png" />
                  <EventSponserLogo title="Typescript" url="https://www.typescriptlang.org/" image="typescript-logo.png" />
                  <EventSponserLogo title="CSS" url="https://developer.mozilla.org/en-US/docs/Web/CSS" image="logo-css.png" />
                  <EventSponserLogo title="HTML" url="https://developer.mozilla.org/en-US/docs/Web/HTML" image="html-logo.png" />
                  <EventSponserLogo title="Solidity" url="https://soliditylang.org/" image="solidity-logo.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="event_sponser_item">
            <div className="row">
              <div className="col-lg-3 d-flex align-items-center">
                <h4 className="h_head sponser_title p-0">Back-End Frameworks</h4>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="row">
                  <EventSponserLogo title="SpringBoot" url="https://spring.io/" image="spring-boot-logo.png" />
                  <EventSponserLogo title="Django" url="https://www.djangoproject.com/" image="django-logo.png" />
                  <EventSponserLogo title="Node" url="https://nodejs.org/en" image="node-logo.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="event_sponser_item">
            <div className="row">
              <div className="col-lg-3 col-md-4  d-flex align-items-center">
                <h4 className="h_head sponser_title p-0">Front-End Frameworks</h4>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="row">
                  <EventSponserLogo title="Angular" url="https://angular.dev/" image="angular-logo.png" />
                  <EventSponserLogo title="React / React Native" url="https://es.react.dev/" image="react-native-logo.png" />
                  <EventSponserLogo title="Vue" url="https://vuejs.org/" image="vue-logo.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="event_sponser_item">
            <div className="row">
              <div className="col-lg-3 col-md-4  d-flex align-items-center">
                <h4 className="h_head sponser_title p-0">Base de datos</h4>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="row">
                  <EventSponserLogo title="MySql" url="https://www.mysql.com/" image="mysql-logo.png" />
                  <EventSponserLogo title="Postgresql" url="https://www.postgresql.org/" image="postgresql-logo.png" />
                  <EventSponserLogo title="MongoDB" url="https://www.mongodb.com/es" image="mongo-db.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventSponser;
