import React from 'react';
import { Link } from 'react-router-dom';

const ErpActionTwo =()=> {
    return(
            <div className="container pb-5">
                <div className="d-flex justify-content-center">
                    <div className='col-10 p-5'>
                <div className="erp_action_content text-center">
                    <img src={require("../img/erp-home/rocket.png")} alt=""/>
                    <h3>Comunícate hoy con nosotros y empieza a accionar en tu futuro</h3>
                    <p>Todo lo que necesitas es un <span>Software</span> para optimizar tu negocio</p>
                    <Link to="/Contact" className="er_btn er_btn_two">Cotiza</Link>
                </div>
                </div>
                </div>
            </div>
    )
}
export default ErpActionTwo;