import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import AppBanner from '../components/Banner/AppBanner';
import AppFeaturesTwo from '../components/Features/AppFeaturesTwo';
import AppFeatures from '../components/Features/AppFeatures';
import AppWork from '../components/Service/AppWork';
import ScreenshowCase from '../components/ScreenshowCase';
import AppGetstarted from '../components/AppGetstarted';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';
import FooterSecurity from '../components/Footer/FooterSecurity';

const Landing = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu"  hbtnClass="er_btn"/>
            <AppBanner/>
            <AppFeaturesTwo/>
            <AppFeatures/>
            <AppWork ServiceData={ServiceData}/>
            <ScreenshowCase/>
            <AppGetstarted/>
            <FooterSecurity FooterData={FooterData}/>
        </div>
    )
}
export default Landing;