import React from 'react';
import Sectitle from "./Title/Sectitle";
import ErpanalyticsItem from "./ErpanalyticsItem";

const Erpanalytics =()=>{
    return(
        <section className="erp_analytics_area">
            <div className="container">
                <Sectitle Title="Escala tu empresa" 
                TitleP="Utiliza la potencia de tecnología de punta para escalar tu empresa a un nuevo nivel."
                 sClass="hosting_title erp_title text-center"/>
                <div className="row">
                    <ErpanalyticsItem analyticsImage="settings1.png" text="Personalización exclusiva para tus necesidades"/>
                    <ErpanalyticsItem analyticsImage="cloud-computing.png" text="Compatible con herramientas de la nube"/>
                    <ErpanalyticsItem analyticsImage="growth.png" text="Disminuye tu esfuerzo, no la productividad"/>
                </div>
            </div>
        </section>
    )
}
export default Erpanalytics;